import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/ramowka',
    name: 'Roadmap',
    component: function () {
      return import('../views/Roadmap.vue')
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: function () {
      return import('../views/Contact.vue')
    }
  },
  {
    path: '/playlist',
    name: 'Playlist',
    component: function () {
      return import('../views/Playlist.vue')
    }
  },
  {
    path: '/zespol',
    name: 'PeopleList',
    component: function () {
      return import('../views/People/List.vue')
    }
  },
  {
    path: '/zespol/:slug',
    name: 'PeopleShow',
    component: function () {
      return import('../views/People/Show.vue')
    },
  },
  {
    path: '/audycje',
    name: 'BroadcastList',
    component: function () {
      return import('../views/Audio/BroadcastList')
    },
    props: true
  },
  {
    path: '/partnerzy',
    name: 'Partners',
    component: function () {
      return import('../views/Partners')
    },
    props: true
  },
  {
    path: '/audycje/:slug',
    name: 'BroadcastShow',
    component: function () {
      return import('../views/Audio/BroadcastShow')
    },
    props: true
  },
  {
    path: '/podcasty',
    name: 'PodcastList',
    component: function () {
      return import('../views/Audio/PodcastList')
    },
    props: true
  },
  {
    path: '/rekomendacje',
    name: 'RecomendationsList',
    component: function () {
      return import('../views/Recomendation/List')
    },
    props: true
  },
  {
    path: '/aktualnosci',
    name: 'ArticlesList',
    component: function () {
      return import('../views/Article/List')
    },
    props: true
  },
  {
    path: '/podcasty/:slug',
    name: 'PodcastShow',
    component: function () {
      return import('../views/Audio/PodcastShow')
    },
    props: true

  },
  {
    path: '/rekomendacje/:slug',
    name: 'RecommendationShow',
    component: function () {
      return import('../views/Recomendation/Show')
    },
    props: true

  },
  {
    path: '/aktualnosci/:slug',
    name: 'ArticleShow',
    component: function () {
      return import('../views/Article/Show')
    },
    props: true

  },
  {
    path: '/newsy/:slug',
    name: 'NewsShow',
    component: function () {
      return import('../views/Audio/PodcastShow')
    },
    props: true
  },
  {
    path: '/:slug',
    name: 'Site',
    component: function () {
      return import('../views/Site')
    },
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
