<template>
  <div class="home">


    <div class="container">
      <div class="row">
        <div class="col s12">
          <PodcastSlider code="main"/>
        </div>
      </div>
    </div>

    <div class="grey lighten-4">
      <div class="container py-5">
        <div class="row">
          <div class="col s12">
            <ArticleNewest :in_row="4" type="block"/>
          </div>
        </div>
      </div>
    </div>

    <div class="container py-5">
      <PodcastNewest :in_row="2"/>
    </div>
    <div class="grey lighten-4">

      <div class="container py-5">
        <div class="row">
          <div class="col s12">
            <RecomendationNewest :in_row="4" type="block"/>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="container py-5">
        <PeopleOnMain/>
      </div>
    </div>

    <div class="grey lighten-4">
      <div class="container py-5">
        <BroadcastOnMain/>
      </div>
    </div>

  </div>

  <SectionInfo code="main"/>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import PodcastNewest from "./Audio/Part/PodcastNewest";
import PeopleOnMain from "./People/Part/OnMain";
import BroadcastOnMain from "./Audio/Part/OnMain";
import SectionInfo from "./Home/SectionInfo";
import PodcastSlider from "@/views/Audio/Part/PodcastSlider";
import RecomendationNewest from "@/views/Recomendation/Part/Newest.vue";
import ArticleNewest from "@/views/Article/Part/Newest.vue";

export default {
  name: 'Home',
  components: {
    ArticleNewest,
    RecomendationNewest,
    PodcastSlider,
    SectionInfo,
    BroadcastOnMain,
    PeopleOnMain,
    PodcastNewest,
    HelloWorld
  }
}
</script>

<style lang="scss" scoped>

.image {
  display: block;
  width: 100%;
  border-radius: 8px;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  overflow: hidden;

  position: relative;

  &-main {
    background-image: url('/img/mission.jpg');
    padding-bottom: 67%;
    margin-bottom: 5%;
  }

  &-broadcast {
    background-image: url('/img/broadcast.jpg');
    padding-bottom: 77%;
    margin-bottom: 5%;
  }

  &-schedule {
    background-image: url('/img/schedule.jpg');
    padding-bottom: 55%;
    margin-bottom: 5%;
  }

  &__title {

    position: absolute;

    left: 30px;
    bottom: 10px;

    //text-transform: uppercase;

    line-height: 20px;
    font-size: 32px !important;
    font-weight: bold;
    white-space: nowrap;
    font-family: "Code-Pro", sans-serif;;


    color: white;
    text-shadow: 1px 1px 10px #000;

    @media screen and (max-width: 720px) {
      font-size: 24px !important;
    }
  }

}


</style>
