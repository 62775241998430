<template>
  <section class="article-list" v-if="articles && articles.length > 0">
    <div class="row mb-0">
      <div class="col s12">
        <div class="title-line mb-4">

          <h2 class="big-title">
            <router-link to="/aktualnosci" title="Aktualności" style="color: #000000">
              Najnowsze aktualności
            </router-link>
          </h2>

        </div>
      </div>
    </div>
    <div class="article-user-section">

      <div class="row row-db mb-0"  v-if="!type || type ==='line'">
        <div class="col s12"
             :class="{ l6: in_row === 2 }"
             v-for="article in articles">
          <ArticleLine :article="article"/>


        </div>
      </div>

      <div class="row row-db mb-0"  v-if="type || type ==='block'">
        <div class="col s12"
             :class="{ l6: in_row === 2, l4: in_row === 3, l3: in_row === 4}"
             v-for="article in articles">
          <ArticleBlock :article="article" v-if="type && type ==='block'"/>



        </div>
      </div>



    </div>
  </section>
</template>

<script>
import axios from "@/axios2";

import ArticleLine from "@/views/Article/Part/Line";
import ArticleBlock from "@/views/Article/Part/Block.vue";
import ComponentListStateMachine from "../../../Extends/ComponentListStateMachine";

export default {
  name: "ArticleNewest",
  extends: ComponentListStateMachine,
  props: ['id_user','in_row','type'],
  components: {
    ArticleBlock,
    ArticleLine,
  },
  data() {
    return {
      articles: null,
    }
  },

  created: function () {

    this.stateUpdate('sendInfo');

    axios
        .get('article/get', {
          params: {
            is_show: true,
            type: 'news',
            count: 4,
            scope: 'title,block_thumb,thumb,date,url,audio,audio_url_force,review_star'
          }
        })
        .then(response => {

          let domain = "https://off.radiokrakow.pl/api/"
          let domain_new = "https://off.radiokrakow.pl/"

          let data = response.data;

          let articles = data.data;

          // articles = CoreArray.changeDomain(articles, 'url', 'audycje', 'podcasty');

          this.articles = articles;

          this.stateUpdate('sendSuccess');

        })
        .catch(e => {
          console.log(e)

          this.stateUpdate('sendWrong');
        })

  }
}
</script>

<style>


</style>
