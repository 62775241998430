<script>
export default {
  name: "Star",
  props: {
    article: {
      default: null
    },
  },
  data: () => ({
    legend: {
      1: 'pomyśl nad alternatywą',
      1.5: 'pomyśl nad alternatywą',
      2: 'na własne ryzyko',
      2.5: 'na własne ryzyko',
      3: 'możesz spróbować',
      3.5: 'możesz spróbować',
      4: 'jest dobrze',
      4.5: 'jest dobrze',
      5: 'i właśnie o to chodzi!',
    }
  }),

  computed: {
    legendText() {
      return this.legend[this.article.review_star];
    }
  }
}
</script>

<template>
  <div class="stars" :title="legendText"><div class="stars__percent" :style="{width:  ((article.review_star/5)*100)+'%'}">⬤⬤⬤⬤⬤</div></div>
</template>

<style scoped lang="scss">

.stars {
  position: relative;
  float: left;
  font-size: 14px;

  display: inline-block;
  //height: 1em;
  //line-height: 1em;

  &:before {
    content: "\2B24\2B24\2B24\2B24\2B24";
    float: left;
    z-index: 1;
    position: relative;
    color: #eee;
    letter-spacing: 2px;
  }
  & .stars__percent {

    position: absolute;
    left: 0;
    //right: 0;
    top: 0;
    bottom: 0;

    float: left;

    color: #1d4cff;
    letter-spacing: 2px;
    z-index: 2;
    overflow: hidden;
    //text-shadow: -1px -1px 0 #1d4cff, 1px -1px 0 #1d4cff, -1px 1px 0 #1d4cff, 1px 1px 0 #1d4cff;

  }
}

</style>
