<template>

  <main class="podcast-list">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>

          <router-link to="/aktualnosci" title="Aktualności" class="breadcrumb">
            Aktualności
          </router-link>
        </div>
      </div>


      <div class="row">
      </div>

      <div class="row">
        <div class="col s12 l8">

          <div class="row row-db" :class="{loading: stateCompare('loading')}">
            <div class="col s12 mb-4">
              <h1 class="big-title">Aktualności</h1>
            </div>
            <div class="col s12">

              <div class="card color darken-1" v-if="articles && articles.length == 0">
                <div class="card-content white-text">
                  <p>Brak</p>
                </div>
              </div>
            </div>
            <div class="col s6" v-for="article in articles" >
              <ArticleBlock :article="article"/>

            </div>
            <div class="col s12">

              <Pagination :page="page" :count="count"/>
            </div>
          </div>

        </div>
        <div class="col s12 l4">
          <ArticleNewest :in_row="1"/>
        </div>
      </div>

    </div>
  </main>
  <SectionInfo code="articlelist"/>
</template>

<script>
import axios from "@/axios2";
import PodcastLine from "@/views/Audio/Part/PodcastLine";
import Pagination from "@/components/Pagination";
import CoreArray from "@/helpers/array.js";
import ComponentListStateMachine from "../../Extends/ComponentListStateMachine";
import SectionInfo from "../Home/SectionInfo";
import ArticleLine from "@/views/Article/Part/Line.vue";
import ArticleBlock from "@/views/Article/Part/Block.vue";
import ArticleNewest from "@/views/Article/Part/Newest.vue";

export default {
  name: "ArticleList",
  components: {ArticleNewest, ArticleBlock, ArticleLine, SectionInfo, Pagination, PodcastLine},
  extends: ComponentListStateMachine,
  data() {
    return {
      'id_user': null,
      'id_family': null,
      'has_text': "",
      'users': null,
      'familys': null,
      'page': 1,
      'count': null,
      'articles': null,
    }
  },
  methods: {
    filterItemClick: function (e, e2) {

      let item = e.target.parentNode;
      let id = item.dataset.value;

      if (item.classList.contains('filter-block-body-item-selected')) {
        id = null;
      }

      let block = e.target.parentNode.parentNode.parentNode
      block.classList.toggle("filter-block-open");

      let name = block.querySelector('input').name;

      if (name === 'id_family')
        this.id_family = id;

      if (name === 'id_user')
        this.id_user = id;

      this.refresh();


    },
    filterHeadClick: function (e) {
      let block = e.target.parentNode
      block.classList.toggle("filter-block-open");
    },
    refresh: function () {

      let domain = "https://radiokrakowkultura.pl/api/"
      let domain_new = "https://radiokrakowkultura.pl/"

      this.stateUpdate('sendInfo');

      axios
          .get('article/get', {
            params: {
              scope: 'title,block_thumb,thumb,date,url,audio,audio_url_force,review_star',
              type: 'news',
              'page': this.page,
              'count': 20,
              'is_show': true
            }
          })
          .then(response => {

            let articles = response.data.data;
            // articles = CoreArray.changeDomain(articles,'thumb',domain,domain_new);
            // articles = CoreArray.changeDomain(articles,'block_thumb',domain,domain_new);
            // articles = CoreArray.changeDomain(articles,'audio_url',domain,domain_new);
            // articles = CoreArray.changeDomain(articles, 'url', 'audycje', 'podcasty');

            this.count = response.data.count;
            this.articles = articles;

            this.stateUpdate('sendSuccess');
          })
          .catch(error => {

            this.stateUpdate('sendWrong');
            this.refresh();

          });
    }
  },
  created() {

    let domain = "https://off.radiokrakow.pl/api/"
    let domain_new = "https://off.radiokrakow.pl/"

    axios
        .get('user/get', {
          params: {
            scope: 'id,firstname,lastname,name,avatar_url,url_full',
            sort: 'lastname',
            count: 100,
            show_on_list: 'yes',
            role: 'editor',
            id_NOTIN: '1935,749',
          }
        })
        .then(response => {

          this.users = response.data.data;
          this.users = CoreArray.changeDomain(this.users, 'avatar_url', domain, domain_new);

        });

    axios
        .get('articlefamily/get', {params: {scope: 'thumb,title,url,id', sort: 'title'}})
        .then(response => {

          this.familys = response.data.data;
          this.familys = CoreArray.changeDomain(this.familys, 'thumb', domain, domain_new);

        })

    this.refresh();

  },

  watch: {
    page: function (val) {
      this.refresh();
    },
    has_text: function (val) {
      this.refresh();
    },
  },

}
</script>

<style scoped lang="scss">
.search-text {

  padding: 15px 12px;
  position: relative;
  &__icon{
    position: absolute;
    right: 24px;
    top: 29px;

  }
  &__input,
  &__input:focus {
    color: #fff;
    text-transform: uppercase;
    font-size: 1.3rem !important;
    border: none !important;
    font-family: Joane;

    box-shadow: none !important;
    outline: none;

  }
}

</style>
